import { Spinner, Stack } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Navigate, useSearchParams } from 'react-router-dom';

import { api } from '@/lib/axios';
import { EXPIRE_TOKEN } from '@/lib/constants';
import { useUserStore } from '@/store/user';
import { User } from '@/types';

async function checkAuthStatus() {
	const { data } = await api.get<{ token: string; user: User }>(
		`/auth/check-auth-status`
	);

	return data;
}

export function ResponsibleGuard({ children }: { children: React.ReactNode }) {
	const { setUser } = useUserStore();
	const [searchParams] = useSearchParams();
	const token = Cookies.get('token') || searchParams.get('token');
	const { data, isLoading } = useQuery(
		['responsible'],
		() => checkAuthStatus(),
		{
			onSuccess: (data) => {
				if (data) {
					setUser(data.user);
				}
			},
		}
	);

	useEffect(() => {
		if (token) {
			Cookies.set('token', token, {
				expires: EXPIRE_TOKEN,
			});
		}
	}, [token]);

	if (isLoading)
		return (
			<Stack w="100%" h="80vh" alignItems="center" justify="center">
				<Spinner />
			</Stack>
		);

	if (!data) {
		return <Navigate to="/auth/login" replace />;
	}

	return children;
}
