import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  DrawerBody,
  DrawerFooter,
  Flex,
  HStack,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Send } from "lucide-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";

import { api } from "@/lib/axios";
import { CAN_RESEND_EMAIL } from "@/lib/constants";
import { useProjectStore } from "@/store/project";
import { useUserStore } from "@/store/user";
import { EmailLog, Indicator } from "@/types";

interface Props {
  indicator: Indicator;
}

const ColorState = {
  sent: "gray",
  opened: "blue",
  click: "green",
  soft_bounce: "yellow",
  hard_bounce: "orange",
  blocked: "orange",
  error: "red",
};

async function fetchEmailLogs(indicator: Indicator, page: number = 1) {
  const { data } = await api.get<{
    data: EmailLog[];
    info: Record<string, number>;
  }>(
    `/full-project/${indicator.project}/indicator/emails/${indicator._id}?page=${page}`
  );
  return data;
}

export function EmailLogs({ indicator }: Props) {
  const { t } = useTranslation();
  const { user } = useUserStore();
  const { project } = useProjectStore();
  const toast = useToast();
  const [isResending, setIsResending] = useState(false);
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["emails", indicator],
    ({ pageParam }) => fetchEmailLogs(indicator, pageParam),
    {
      getNextPageParam: ({ info }) => info.next ?? false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const logs = useMemo(
    () =>
      data?.pages.reduce((prev, page) => {
        return {
          info: page.info,
          data: [...prev.data, ...page.data],
        };
      }),
    [data]
  );

  const resendEmail = async () => {
    setIsResending(true);
    try {
      await api.post(`/responsible/${indicator._id}/resend-email`);
      toast({
        status: "success",
        title: "Email resent successfully",
        duration: 2000,
      });
    } catch (error) {
      toast({
        status: "error",
        title: "Failed to resend email",
        duration: 2000,
      });
    } finally {
      setIsResending(false);
    }
  };

  const canResendEmail = useMemo(() => {
    if (!user || !project) return false;
    const userMember = project.members.find(
      (member) => member.email === user.email
    );
    if (!userMember) return false;
    const userRole = userMember.role;
    return CAN_RESEND_EMAIL.includes(userRole);
  }, [user, project]);

  return (
    <Flex direction="column" h="100%">
      <DrawerBody flex="1" overflowY="auto" overflowX="hidden">
        {logs?.data.map(({ _id, email, events, createdAt }) => (
          <Accordion key={_id} allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Text>{email}</Text>
                    <Text fontSize="sm" color="gray">
                      {new Date(createdAt).toString()}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack spacing={8}>
                  {events.map(({ date, event }) => (
                    <HStack key={`${date}-${event}`} justify="space-between">
                      <Badge
                        rounded="lg"
                        px={4}
                        py={1}
                        colorScheme={
                          ColorState[event as keyof typeof ColorState]
                        }
                      >
                        {t(`alerts.${event}`)}
                      </Badge>
                      <Text>{new Date(date).toString()}</Text>
                    </HStack>
                  ))}
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        ))}
      </DrawerBody>

      <DrawerFooter>
        <Flex flexDir="column" w="full">
          {logs?.data && logs.data.length > 0 && canResendEmail && (
            <Button
              width="full"
              colorScheme="brand"
              variant="outline"
              isLoading={isResending}
              loadingText={t("resending")}
              onClick={resendEmail}
            >
              <HStack gap={2}>
                <Text>{t("resend")}</Text>
                <Send size={14} />
              </HStack>
            </Button>
          )}
          {logs?.data && hasNextPage && (
            <Button
              onClick={() => fetchNextPage()}
              mt={4}
              width="full"
              isLoading={isLoading}
            >
              {t("load_more")}
            </Button>
          )}
        </Flex>
      </DrawerFooter>
    </Flex>
  );
}
