import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Trash } from "lucide-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { api } from "@/lib/axios";
import { useProjectStore } from "@/store/project";
import { Indicator } from "@/types";

interface RemoveModalProps {
  indicator: Indicator;
}

export const RemoveModal = ({ indicator }: RemoveModalProps) => {
  const { removeIndicator } = useProjectStore();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  // const cancelRef = React.useRef()
  const cancelRef = useRef(null);
  const toast = useToast();

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await api.delete(`/indicator/${indicator._id}`);
      // await api.patch(`/indicator/${indicator._id}`, { paused: true });

      removeIndicator(indicator);
      onClose();
      toast({
        title: t("indicator.messages.deleted"),
      });
    } catch (error) {
      toast({
        status: "error",
        title: "Failed remove indicator",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onOpenModalClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onOpen();
  };

  return (
    <>
      <Button
        justifyContent="flex-start"
        leftIcon={<Trash size={16} />}
        colorScheme="red"
        variant="ghost"
        size="xs"
        iconSpacing={3}
        ml={1}
        onClick={onOpenModalClick}
      >
        {t("indicator.remove")}
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("indicator.remove")}
            </AlertDialogHeader>

            <AlertDialogBody>{t("indicator.messages.msg")}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} colorScheme="gray">
                {t("cancel")}
              </Button>
              <Button
                colorScheme="red"
                onClick={handleDelete}
                isLoading={isLoading}
                ml={3}
              >
                {t("delete")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
