import {
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { EmailLogs } from '@/components/indicator/EmailLogs';
import { IndicatorInformation } from '@/components/indicator/Information';
import { ValueLogs } from '@/components/indicator/ValueLogs';
import { Values } from '@/components/indicator/Values';
import { useIndicatorStore } from '@/store/indicator';

export const UpdateIndicator = () => {
	const { indicator, removeIndicator } = useIndicatorStore();
	const { t } = useTranslation();

	if (!indicator) return null;

	return (
		<Drawer placement="right" size="lg" onClose={removeIndicator} isOpen>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader>
					<Heading size="md">{indicator.name}</Heading>
					<Text fontSize="xs" color="gray">
						{t('created_at')} {dayjs(indicator.createdAt).format('MM-DD-YYYY')}
					</Text>
				</DrawerHeader>

				<Tabs as={DrawerBody} colorScheme="brand" isLazy isFitted>
					<TabList>
						<Tab>{t('values')}</Tab>
						<Tab>{t('information')}</Tab>
						<Tab>{t('indicator.history_changes')}</Tab>
						<Tab>{t('indicator.email_events')}</Tab>
					</TabList>

					<TabPanels h="calc(100% - 75px)" >
						<TabPanel h="100%" px={0}>
							<Values onClose={removeIndicator} indicator={indicator} />
						</TabPanel>
						<TabPanel h="100%" px={0}>
							<IndicatorInformation
								onClose={removeIndicator}
								indicator={indicator}
							/>
						</TabPanel>
						<TabPanel px={0}>
							<ValueLogs indicator={indicator} />
						</TabPanel>
						<TabPanel px={0}>
							<EmailLogs indicator={indicator} />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</DrawerContent>
		</Drawer>
	);
};
