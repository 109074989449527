import { Spinner, Stack } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import ProjectLayout from '@/layouts/ProjectLayout';
import { api } from '@/lib/axios';
import { useProjectStore } from '@/store/project';

async function fetchProjectById(id?: string) {
	if (!id) return null;
	const { data } = await api.get(`/full-project/${id}`);

	if (!data.project) return null;

	return { ...data.project, groups: data.groups };
}

export function ProjectGuard() {
	const { setProject } = useProjectStore();
	const { id } = useParams<{ id: string }>();

	const { data, isLoading } = useQuery(
		['project', id],
		() => fetchProjectById(id),
		{
			onSuccess: (data) => {
				if (data) setProject(data);
			},
		}
	);

	if (isLoading)
		return (
			<Stack w="100%" h="80vh" alignItems="center" justify="center">
				<Spinner />
			</Stack>
		);

	if (!data) return <Navigate to="/app/dashboard" replace />;

	return (
		<ProjectLayout>
			<Outlet />
		</ProjectLayout>
	);
}
