import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Card,
	CardHeader,
	Container,
	Heading,
	SimpleGrid,
	Skeleton,
	Stack,
	Text,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

import { api } from '@/lib/axios';

interface ResponsibleResponse {
	name: string;
	_id: string;
	indicators: number;
}

async function fetchProjects() {
	const { data } = await api.get('/responsible');

	return data;
}

const ProjectsList = () => {
	const { isLoading, data, isError, error } = useQuery<
		ResponsibleResponse[],
		AxiosError
	>({
		queryKey: ['data'],
		queryFn: () => fetchProjects(),
		refetchOnWindowFocus: false,
	});

	const { t } = useTranslation();

	return (
		<Container as={Stack} spacing={8} maxW={'6xl'} my={10}>
			<Stack textAlign={'center'} spacing={{ base: 2, md: 4 }}>
				<Heading fontWeight={600} fontSize={{ base: '5xl', md: '6xl' }}>
					{t('responsible.title.top')} <br />
					<Text as={'span'} color="primary">
						{t('responsible.title.bottom')}
					</Text>
				</Heading>
				<Text color="text" fontSize={['md', 'lg']}>
					{t('responsible.description')}
				</Text>
			</Stack>

			{/* Select Project */}
			<Heading>{t('projects')}</Heading>

			<SimpleGrid
				spacing={8}
				templateColumns="repeat(auto-fill, minmax(350px, 1fr))"
			>
				{isLoading &&
					[1, 2, 3].map((item) => (
						<Skeleton key={`skeleton-project-${item}`} h={24} />
					))}
				{!isLoading &&
					data?.map(({ name, _id, indicators }) => (
						<Card
							as={RouterLink}
							to={`/responsible/project/${_id}`}
							key={_id}
							bg="whitesmoke"
							alignItems="flex-start"
						>
							<CardHeader>
								<Heading size="md">{name}</Heading>
								<Text color="gray" mt={2}>
									{t('myIndicators')} {indicators}
								</Text>
							</CardHeader>
						</Card>
					))}
			</SimpleGrid>
			{isError && (
				<Alert status="error">
					<AlertIcon />
					<AlertTitle>Error</AlertTitle>
					<AlertDescription>
						{error?.message || 'Something went wrong'}
					</AlertDescription>
				</Alert>
			)}
		</Container>
	);
};

export default ProjectsList;
