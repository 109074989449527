import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Stack,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Select as ReactSelect } from "chakra-react-select";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { api } from "@/lib/axios";
import { useProjectStore } from "@/store/project";
import { useUserStore } from "@/store/user";
import { Indicator } from "@/types";

import { DateSelect } from "./DateSelect";

const schema = z.object({
  name: z.string().min(3),
  description: z.string(),
  project: z.string().min(3),
  group: z.string().min(3),
  responsible: z.string().email().optional().or(z.literal("")),
  request_data: z.object({
    isPreviousMonth: z.boolean().default(false),
    ofType: z.string().min(3).default("never"),
    days: z.array(z.number()).optional(),
    months: z.array(z.number()).optional(),
  }),
});

type Schema = z.infer<typeof schema>;
interface Props {
  onClose: () => void;
  indicator?: Indicator;
}

export function IndicatorInformation({ onClose, indicator }: Props) {
  const { user } = useUserStore();
  const { project, addIndicator, updateIndicator } = useProjectStore();
  const [days, setDays] = useState<number[]>(
    indicator?.request_data?.days || []
  );
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
    setValue,
    reset,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      project: project?._id,
      ...indicator,
    },
  });
  const { t } = useTranslation();
  const toast = useToast();
  const type = useWatch({
    control,
    name: "request_data.ofType",
  });

  const onSubmit = async (formData: Schema) => {
    try {
      if (!indicator) {
        const { data } = await api.post<Indicator>("/indicator", {
          ...formData,
          responsible: formData.responsible || user?.email,
        });
        addIndicator(data);
        handleClose();
        return;
      }

      const { data } = await api.patch(
        `/indicator/${indicator?._id}`,
        formData
      );
      updateIndicator(data);
      toast({
        title: "The indicator was successfully updated!",
        status: "success",
      });
    } catch (error) {
      toast({
        status: "error",
        title: "There was an error updating the indicator ",
      });
    }
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  useEffect(() => {
    setValue("request_data.days", days);
  }, [days, setValue]);

  useEffect(() => {
    if (type === "custom" || type === "annual") {
      if (!indicator || !indicator.request_data?.months) {
        setValue("request_data.months", [], { shouldValidate: true });
      } else {
        setValue("request_data.months", indicator.request_data.months);
      }
    }
  }, [type, setValue, indicator]);

  return (
    <SimpleGrid
      templateRows="1fr auto"
      h="full"
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={6} overflowY="auto" overflowX="hidden">
        <FormControl isInvalid={!!errors.name} isRequired>
          <FormLabel htmlFor="name">{t("name")}</FormLabel>
          <Input id="name" {...register("name")} />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.description}>
          <FormLabel htmlFor="description">
            {t("indicator.description")}
          </FormLabel>
          <Textarea id="description" {...register("description")} />
          <FormErrorMessage>
            {errors.description && errors.description.message}
          </FormErrorMessage>
        </FormControl>
        {!indicator && (
          <FormControl isInvalid={!!errors.group} isRequired>
            <FormLabel htmlFor="group">{t("group.index")}</FormLabel>
            <Controller
              control={control}
              name="group"
              render={({ field }) => (
                <ReactSelect
                  options={project?.groups.map((group) => ({
                    label: group.name,
                    value: group._id,
                  }))}
                  selectedOptionStyle="check"
                  onChange={(newValue) => {
                    if (!newValue) return;
                    field.onChange(newValue.value);
                  }}
                />
              )}
            />
            <FormErrorMessage>
              {errors.group && errors.group.message}
            </FormErrorMessage>
          </FormControl>
        )}
        <FormControl isInvalid={!!errors.responsible}>
          <FormLabel htmlFor="responsible">{t("responsible.index")}</FormLabel>
          <Input
            id="responsible"
            type="email"
            {...register("responsible")}
            placeholder={user?.email}
          />
          <FormErrorMessage>
            {errors.responsible && errors.responsible.message}
          </FormErrorMessage>
        </FormControl>
        <SimpleGrid gap={4}>
          <DateSelect
            control={control}
            register={register}
            type={type}
            days={days}
            setDays={setDays}
            errors={errors}
          />
        </SimpleGrid>
      </Stack>

      <Flex justify="flex-end">
        <Button
          colorScheme="gray"
          mr={3}
          onClick={handleClose}
          isDisabled={isSubmitting}
        >
          {t("cancel")}
        </Button>
        <Button type="submit" isLoading={isSubmitting}>
          {t("save")}
        </Button>
      </Flex>
    </SimpleGrid>
  );
}
