import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Input,
  Select,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { PlusIcon, Trash } from "lucide-react";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { api } from "@/lib/axios";
import { ROLES } from "@/lib/constants";
import { members as schema } from "@/lib/validations/project";
import { useProjectStore } from "@/store/project";
import { Project } from "@/types";

type Schema = z.infer<typeof schema>;

export function ProjectMembers() {
  const { project, updateProject } = useProjectStore();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      members: project?.members,
    },
  });
  const { fields, append, remove } = useFieldArray<Schema>({
    control,
    name: "members",
  });
  const toast = useToast();
  const { t } = useTranslation();

  const onSubmit = async (formData: Schema) => {
    try {
      const { data } = await api.patch<Project>(`/project/${project?._id}`, {
        ...formData,
        id: project?._id,
      });
      updateProject(data);
      toast({
        status: "success",
        title: "Members Updated",
      });
    } catch (error) {
      toast({
        status: "error",
        title: "Error to update members",
      });
    }
  };

  return (
    <Card as="form" onSubmit={handleSubmit(onSubmit)}>
      <CardHeader
        as={Flex}
        gap={6}
        direction={{ base: "column", md: "row" }}
        justify="space-between"
      >
        <div>
          <Heading as="h3" size="md">
            {t("members")}
          </Heading>
          <Text>{t("project.membersMsg")}</Text>
        </div>
        <Button
          size="sm"
          colorScheme="gray"
          leftIcon={<PlusIcon size={14} />}
          onClick={() =>
            append({
              email: "",
              role: ROLES[0],
              canReceiveEmail: false,
            })
          }
        >
          {t("project.addMember")}
        </Button>
      </CardHeader>

      <CardBody>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>{t("email.index")}</Th>
                <Th>{t("receive_summary")}</Th>
                <Th>{t("role")}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {fields.map((item, index) => (
                <Tr key={index}>
                  <Td>
                    {" "}
                    <FormControl
                      isInvalid={
                        errors?.members && !!errors.members[index]?.email
                      }
                      size="sm"
                    >
                      <Input
                        {...register(`members.${index}.email`)}
                        size="sm"
                        readOnly={!!item._id}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl
                      isInvalid={
                        errors?.members && !!errors.members[index]?.email
                      }
                      size="sm"
                      display="flex"
                      justifyContent="center"
                    >
                      <Switch
                        isReadOnly={item.role === "owner"}
                        {...register(`members.${index}.canReceiveEmail`)}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl
                      key={item.id}
                      isInvalid={
                        errors?.members && !!errors.members[index]?.role
                      }
                      size="sm"
                    >
                      <Select
                        size="sm"
                        placeholder="Select role"
                        isDisabled={item.role === "owner"}
                        {...register(`members.${index}.role`)}
                      >
                        <option value="owner" disabled>
                          {t("owner")}
                        </option>
                        {ROLES.map((role) => (
                          <option value={role} key={`${role}-${index}`}>
                            {t(role)}
                          </option>
                        ))}
                      </Select>{" "}
                    </FormControl>
                  </Td>
                  <Td>
                    {" "}
                    <IconButton
                      colorScheme="red"
                      size="sm"
                      icon={<Trash size={16} />}
                      aria-label="Remove member"
                      isDisabled={item.role === "owner"}
                      onClick={onOpen}
                    />
                    <DeleteModal
                      isOpen={isOpen}
                      onClose={onClose}
                      onRemove={() => remove(index)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
      <CardFooter justify="flex-end">
        <Button isLoading={isSubmitting} type="submit">
          {t("save")}
        </Button>
      </CardFooter>
    </Card>
  );
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRemove: () => void;
}

function DeleteModal({ isOpen, onRemove, onClose }: ModalProps) {
	const { t } = useTranslation();
  const cancelRef = React.useRef(null);

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
						{t("project.removeMember")}
          </AlertDialogHeader>

          <AlertDialogBody>
						{t("project.remove.member")}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button  colorScheme="gray" ref={cancelRef} onClick={onClose}>
              {t("cancel")}
            </Button>
            <Button colorScheme="red" onClick={onRemove} ml={3}>
              {t("delete")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
